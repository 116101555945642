main {
	// overflow-x: hidden;
}

p {
	@if $type =="" {
		font-weight: 500;
	}

	@if $type ==sp {
		font-size: 14px;
	}

	@if $type ==pc {
		font-size: 18px;
	}
}

a {
	@if $type =="" {
		text-decoration: none;
		color: #000;
	}

}


.section+.section {
	@if $type ==pc {
		padding-top: 120px;
		padding-bottom: 120px;
	}

	@if $type ==tb {
		padding-top: 80px;
		padding-bottom: 80px;
	}
}

.inner {
	@if $type ==sp {
		padding-inline: 20px
	}

	@if $type ==pc {
		@include maxPC;
	}
}

#ghMenuBtn {
	@if $type ==sp {
		top: 10px;
	}

	@if $type ==tb {
		top: 10px;
	}
}

.h2TitleWrap {
	color: $c_base;
	position: relative;

	.en {
		@if $type =="" {
			font-weight: 900;
			@include fontEn;
			font-style: italic;
			position: relative;
		}

		@if $type ==sp {
			font-size: calc(55px/2);
		}

		@if $type ==pc {
			font-size: 55px;
		}

		&::before {
			@if $type =="" {
				content: "";
				position: absolute;
				background: $c_base;
				left: 0;
				bottom: 10px;
				width: 100%;
				height: 3px;
			}

			@if $type ==sp {
				bottom: 4px;
			}
		}
	}

	.homeH2 {
		@if $type =="" {
			font-weight: 600;
			letter-spacing: 0.06em;
		}

		@if $type ==sp {
			font-size: 19px;
			line-height: 1;
		}

		@if $type ==pc {
			font-size: 28px;
		}
	}

	&.hasRec {
		&::before {
			position: absolute;
			content: "";
			background: url(../images/home/rec-img.png);
			background-size: contain;
			background-repeat: no-repeat;
			top: 10px;
			left: -50px;
			width: 116px;
			height: 154px;
			aspect-ratio: 229/306;
			z-index: -1;

			@if $type ==sp {
				width: 14.9vw;
				background-repeat: no-repeat;
				left: 0;
			}
		}
	}
}

.homeH3 {
	@if $type =="" {
		font-weight: 600;
		letter-spacing: 0.06em;
		color: #000;
	}

	@if $type ==sp {
		font-size: 20px;
	}

	@if $type ==pc {
		font-size: 38px;
	}
}






#fv {
	@if $type =="" {
		position: relative;
	}

	@if $type ==sp {
		background: url(../images/home/fv-bg_sp.png);
		background-position: center top 0px;
		background-repeat: no-repeat;
		background-size: cover;
		padding-top: 115px;

	}

	@if $type ==pc {
		background: url(../images/home/fv-bg.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		min-height: 700px;
		padding-top: 303px;
		padding-bottom: 75px;
	}

	.fvInner {
		@if $type ==pc {
			margin-inline: auto;
			max-width: calc(100% - 80px);
			width: 90%;
		}

		.fvTextWrap {
			@if $type ==sp {
				padding-left: 20px;
			}

			.fvCatch {
				@if $type =="" {
					position: relative;
					display: inline-block;
					// overflow: hidden;
					// vertical-align: top;
				}

				@if $type ==sp {
					max-width: 243px;
					width: 100%;
				}

				@if $type ==pc {
					max-width: calc(1025px/2);
					width: 32vw;
				}

				.img {
					@if $type =="" {
						// overflow: hidden;
						// display: inline-block;
						// transform: translateY(120%);
						// transition: transform 1.3s .075s cubic-bezier(0.4, 0, 0, 1);
					}
				}

			}

			.fvText {
				@if $type =="" {
					position: relative;
					// overflow: hidden;
					// display: inline-block;
					// vertical-align: top;
				}

				@if $type ==sp {
					max-width: 167px;
					margin-top: 16px;
					width: 100%;
					line-height: 1;
				}

				@if $type ==pc {
					max-width: calc(870px/2);
					margin-top: 50px;
					width: 27.5vw;
				}

				.img {
					@if $type =="" {
						// display: inline-block;
						// transform: translateY(120%);
						// transition: transform 1.3s .075s cubic-bezier(0.4, 0, 0, 1);
						// position: relative;
					}

					&::before {
						@if $type =="" {
							position: absolute;
							content: "";
							background: $c_base;
							height: 3px;
							width: 100%;
							left: 0;
							bottom: 0;
						}

						@if $type ==sp {
							height: 1px;
							bottom: 0px;
						}
					}
				}

				img {}

			}
		}

		.fvImgWrap {
			@if $type ==sp {
				margin-top: 18px;
			}

			.mainImg {
				@if $type ==sp {
					// max-width: 270px;
					// width: 100%;
					width: 73.3vw;
				}
			}

			.imgRight {
				@if $type ==sp {
					position: absolute;
					right: 0;
					top: 165px;

					.mainImg {
						// width: 100%;
						// max-width: calc(395px/2);
						width: 52vw;
					}


				}

			}
		}
	}

	.fvNavWrap {
		@if $type =="" {
			display: flex;
		}

		@if $type ==sp {
			gap: 4px;
			width: calc(100% - 20px);
			margin-inline: auto;
			margin-top: 20px;
		}

		@if $type ==pc {
			max-width: 830px;
			width: 51.8vw;
			gap: 14px;
			margin-top: min(14.8vw, 238px);
			position: relative;
			z-index: 10;
		}

		li {
			@if $type =="" {
				border-radius: 8px;
				box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
			}

			@if $type ==sp {
				width: calc((100% - 8px)/3);
			}

			@if $type ==pc {
				width: calc((100% - 20px)/3);
			}

			a {
				@if $type =="" {
					position: relative;
					font-weight: 600;
					color: #fff;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 8px;
					box-shadow: 0px 0px 6px -4px #000;
				}

				@if $type ==sp {
					flex-direction: column;
					gap: 10px;
					font-size: 16px;
					letter-spacing: -0.025em;
					line-height: 1.25;
					text-align: center;
					padding: 8px;
					min-height: 105px;
					align-items: center;
				}

				@if $type ==pc {
					padding-block: min(1.25vw, 20px);
					font-size: min(1.43vw, 23px);
					gap: 20px;
					line-height: 1.21;
					min-height: 100px;
				}

				&:hover,
				&:focus {
					@if $type ==pc {
						transition: all 0.3s;
						opacity: 0.9;
					}
				}

				.num {
					@if $type =="" {
						position: absolute;

						top: 8px;
						color: #fff;
						@include fontTel;
						font-weight: normal;
					}

					@if $type ==sp {
						font-size: 12px;
						left: 10px;
					}

					@if $type ==pc {
						font-size: min(1.12vw, 18px);
						left: 16px;
					}
				}

				.icon {
					@if $type =="" {}

					.lawyer {

						@if $type ==sp {
							max-width: 26px;
							width: 100%;
						}

						@if $type ==pc {
							max-width: 46px;
							width: 100%;
							width: min(2.87vw, 46px);
						}
					}

					.doctor {
						@if $type ==sp {
							max-width: 20px;
							width: 100%;
						}

						@if $type ==pc {
							// max-width: 36px;
							// width: 100%;
							width: min(2.25vw, 36px);
						}
					}

					.frasko {
						@if $type ==sp {
							max-width: 19px;
							width: 100%;
						}

						@if $type ==pc {
							max-width: 33px;
							width: min(2.06vw, 33px);
						}
					}

				}

				&.green {
					background: $c_base;
				}

				&.skyblue {
					background: #2b91c1;
				}

				&.blue {
					background: #3363ab;
				}
			}


		}

	}

	.fvImgWrap {
		@if $type ==pc {
			position: absolute;
			right: 0;
			display: flex;
			width: 74.6vw;
			top: 160px;
			height: auto;
			max-height: 736px;
			aspect-ratio: 736/1018;
		}

		>.mainImg {
			@if $type ==pc {
				position: relative;
				left: 10.8vw
			}
		}

		.imgRight {
			.mainImg+.mainImg {
				@if $type ==pc {
					margin-top: 3.1vw;
				}
			}
		}
	}

	.fixedNav {
		@if $type =="" {}

		@if $type ==pc {
			position: absolute;
			right: 40px;
			background: rgba(255, 255, 255, 0.8);
			;
			top: 50%;
			padding: 24px 20px 20px;
			border-radius: 12px;
			max-width: 320px;
			display: block;
			box-shadow: 0px 0px 6px -4px #000;
			width: 100%;
			z-index: 10;
		}

		@if $type ==tb {
			display: none;
		}

		.title {
			@if $type =="" {
				background: #f3961e;
				color: #fff;
				border-radius: 6px;
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
			}

			@if $type ==pc {
				font-size: 18px;
				font-weight: 600;
				padding: 6px;
				gap: 12px;
			}

			.mailIcon {
				@if $type ==pc {
					max-width: 21px;
					display: flex;
					align-items: center;
				}

				img {
					width: 100%;
				}
			}

			&:hover,
			&:focus {
				@if $type ==pc {
					transition: all 0.3s;
					opacity: 0.9;
				}
			}
		}

		ul {
			@if $type ==pc {
				margin-top: 8px;
			}

			li {
				@if $type ==pc {
					padding-left: 20px;
				}

				&+li {
					@if $type ==pc {
						margin-top: 5px;
					}
				}

				a {
					@if $type =="" {
						background: #fff;
						border-radius: 8px;
						box-shadow: 0px 0px 6px -4px #000;
						font-weight: 60;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						padding-left: 30px;
						padding-right: 28px;
					}


					@if $type ==pc {
						font-size: 15px;
						flex-direction: column;
						padding-top: 8px;
						padding-bottom: 14px;
					}

					&:hover,
					&:focus {

						@if $type ==pc {
							transition: all 0.3s;
							opacity: 0.9;
						}
					}

					.navTitle {
						@if $type =="" {
							font-weight: 600;
							text-align: center;
							letter-spacing: 0.025em;
							display: block;
						}

						@if $type ==pc {
							font-size: 15px;
							text-align: left;
							width: 100%;
							padding-left: 3px;
						}
					}

					.telNum {
						@if $type =="" {
							font-weight: 600;
							display: block;
							@include fontTel;
							letter-spacing: 0.05em;
						}

						@if $type ==pc {
							font-size: 27px;
							line-height: 1;
							margin-top: 4px;
						}
					}

					&.telIcon {}

					&.doctorIcon {
						.telNum {
							color: #2b91c1;
						}
					}

					&.fraskoIcon {
						.telNum {
							color: #3363ab;
						}
					}

					.phoneCircle,
					.doctorCircle,
					.fraskoCircle {
						@if $type ==pc {
							position: absolute;
							left: -22px;
							top: 50%;
							transform: translateY(-50%);
							width: 32px;
							height: 32px;
							border-radius: 50px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 10px #fff solid;
							box-sizing: content-box;
						}

					}

					.phoneCircle {
						@if $type =="" {
							background: #f3961e;
						}

						img {
							@if $type =="" {
								max-width: 14px;
								width: 100%;
							}
						}

					}

					.doctorCircle {
						@if $type =="" {
							background: #2b91c1;
						}

						img {
							@if $type =="" {
								max-width: 17px;
								width: 100%;
							}
						}

					}

					.fraskoCircle {
						@if $type =="" {
							background: #3363ab;
						}

						img {
							@if $type =="" {
								max-width: 17px;
								width: 100%;
							}
						}
					}

					&:hover,
					&:focus {
						@if $type ==pc {
							opacity: 0.8s;
						}
					}
				}
			}
		}

		.info {
			@if $type =="" {
				line-height: 1.25;
				letter-spacing: 0.05em;
				text-align: center;
			}

			@if $type ==pc {
				font-size: 14px;
				margin-top: 10px;
			}
		}
	}

}



.slideIn {
	// animation-name: slideInAnime;
	// animation-duration: 0.5s;
	// animation-fill-mode: forwards;
	// opacity: 0;
	opacity: 0;
	animation: slideInAnime 0.45s cubic-bezier(0.25, 0.1, 0.25, 1.0) both;
}

@keyframes slideInAnime {
	0% {
		opacity: 0;
		transform: translateY(-150px);
	}

	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}


.slideInNext {
	// animation-name: slideInAnime2;
	// animation-duration: 0.5s;
	// animation-delay: 0.5s;
	// animation-fill-mode: forwards;
	opacity: 0;
	animation: slideInAnime2 0.45s cubic-bezier(0.25, 0.1, 0.25, 1.0) 0.3s both;
}

@keyframes slideInAnime2 {
	0% {
		transform: translateY(-150px);
		opacity: 0;
	}

	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}


.fadeIn {
	animation-name: fadeInAnime;
	animation-duration: 0.8s;
	animation-fill-mode: forwards;
	opacity: 0;
	animation-delay: 2s;
}

@keyframes fadeInAnime {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}


.fadeUp {
	// animation-name: fadeUpAnime;
	// animation-duration: 0.5s;
	// animation-fill-mode: forwards;
	animation: fadeUpAnime 0.4s ease-out forwards;
	animation-delay: 1s;
}



@keyframes fadeUpAnime {
	to {
		transform: translateY(0%);
		transition: transform 1s 0s cubic-bezier(0.25, 0, 0, 1);
	}
}




#about {
	@if $type =="" {
		position: relative;
		overflow: hidden;
	}

	@if $type ==sp {
		margin-top: 40px;
		padding-bottom: 35px;
	}

	@if $type ==pc {
		background: url(../images/home/about-bg.png);
		background-size: cover;
		background-position: center;
		padding-bottom: 110px;
		margin-top: 105px;
	}


	&::before {
		@if $type ==sp {
			position: absolute;
			content: "";
			background: url(../images/home/about-bg_sp.png);
			background-position: top left;
			background-repeat: no-repeat;
			background-size: contain;
			bottom: 0;
			left: 0;
			max-height: 891px;
			width: 100%;
			height: 100%;

		}
	}


	&::after {
		@if $type ==pc {
			position: absolute;
			content: "";
			background: url(../images/home/rec-img.png);
			background-position: center center;
			background-repeat: no-repeat;
			right: -40px;
			bottom: -80px;
			width: 146px;
			height: 235px;
			background-size: contain;
		}
	}


	.aboutSideImg {
		@if $type ==sp {
			width: calc(100% + 40px);
			margin-inline: auto;
			margin-top: 60px;
			clip-path: polygon(0 0, 100% 0, 87% 100%, 0% 100%);
			margin-bottom: 60px;
			aspect-ratio: 750/461;
		}

		@if $type ==pc {
			position: absolute;
			left: 0;
			top: 12.9vw;
			width: 56.25vw;
			max-height: 466px;
			clip-path: polygon(0 0, 100% 0, 87% 100%, 0% 100%);
		}
	}



	.inner {
		position: relative;
		z-index: 5;

		.aboutCatch {
			@if $type ==sp {
				width: 65.3%;
				max-width: 415px;
				margin-top: 20px;
				position: relative;
				z-index: 10;
			}

			@if $type ==pc {
				margin-left: auto;
				max-width: 464px;
				width: 100%;
				padding-right: 40px;
				margin-top: 30px;
			}

			img {
				aspect-ratio: 930/250;
			}
		}

		.aboutText {
			@if $type ==sp {
				margin-top: 14px;
			}

			@if $type ==pc {
				font-size: 18px;
				margin-left: auto;
				// width: 38.1%;
				width: 42.1%;
				margin-top: 42px;
				line-height: 2.38;
			}




		}

		.btnWrap {
			@if $type =="" {
				text-align: right;

			}

			@if $type ==sp {
				margin-top: 24px;
			}

			@if $type ==pc {

				margin-top: 50px;
			}

			a {
				@if $type =="" {
					color: #fff;
					background: $c_base;
				}

				@if $type ==sp {
					width: 190px;
					min-width: unset;
					padding-left: 22px;
					padding-right: 32px;
					font-size: 15px;
				}

				.c-arrow {
					&::before {
						background: #fff;
					}

					&::after {
						@if $type =="" {
							position: absolute;
							content: "";
							background: url(../images/common/arrow-green.svg);
							background-size: contain;
							background-repeat: no-repeat;
							aspect-ratio: 83/150;
						}

						@if $type ==pc {
							width: 6px;
							height: auto;
							left: 50%;
						}
					}
				}
			}
		}


		// newsエリア
		.newsWrap {

			@if $type =="" {
				margin-inline: auto;

			}

			@if $type ==pc {
				justify-content: space-between;
				margin-top: 170px;
				display: flex;
				gap: min(3.75vw, 60px);
			}

			.newsLeft {
				@if $type ==sp {
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				@if $type ==pc {
					width: calc((100% - 60px) - 80%);
					min-width: 160px;
				}


				.titleWrap {
					@if $type =="" {
						display: flex;
					}

					@if $type ==sp {
						align-items: baseline;
						gap: 12px;
					}

					@if $type ==pc {

						flex-direction: column;
					}


					.en {
						@include fontEn;
						color: $c_base;
						font-weight: 700;
						font-style: italic;

						@if $type ==sp {
							font-size: 24px;
						}

						@if $type ==pc {
							font-size: 30px;
						}
					}

					.title {
						@if $type =="" {
							color: $c_base;
							font-weight: 600;
							line-height: 1.2;
							letter-spacing: 0.06em;
						}

						@if $type ==sp {
							font-size: 14px;
						}

						@if $type ==pc {
							font-size: 22px;
							margin-top: 10px;
						}
					}

					&+a {
						@if $type =="" {
							color: $c_base;
							background: #fff;
						}

						@if $type ==sp {
							margin: 0;
							width: 97px;
							font-size: 9px;
							min-width: unset;
							padding-right: 26px;
							padding-left: 10px;
						}

						@if $type ==pc {
							margin-top: 40px;
							font-size: 16px;
							padding: 12px 16px 12px 14px;
						}

						.c-arrow {
							@if $type ==sp {
								width: 12px;
								height: 12px;
							}

							@if $type ==pc {
								right: 10px;
							}

							&::before {
								@if $type ==sp {
									width: 12px;
									height: 12px;
								}
							}

							&::after {
								@if $type =="" {
									position: absolute;
									content: "";
									background: url(../images/common/arrow-white.svg);
									background-size: contain;
									background-repeat: no-repeat;
									aspect-ratio: 83/150;

								}

								@if $type ==pc {
									width: 5px;
									height: auto;
									left: 50%;
								}
							}
						}
					}
				}
			}


			.newsListArea {
				@if $type ==pc {
					margin-top: 30px;
					width: 80%;

				}

				.newsItem {
					a {
						@if $type =="" {
							display: flex;
							position: relative;
							display: block;

						}

						@if $type ==sp {
							flex-direction: column;
							padding-top: 14px;
							padding-bottom: 14px;
							border-bottom: solid 1px #aaa;

						}

						@if $type ==pc {
							position: relative;
							border-radius: 10px;
							align-items: center;
							padding: 23px 48px 23px 30px;
							transition: 0.2s ease-in-out;
						}

						&::before {
							@if $type ==pc {
								content: "";
								position: absolute;
								background: #bdd7a9;
								height: 1px;
								width: 98%;
								right: 0;
								bottom: 0px;
								transition: 0.2s ease-in-out;
							}
						}

						.textArea {
							@if $type =="" {
								display: flex;
							}

							@if $type ==sp {
								flex-direction: column;
							}

							@if $type ==pc {
								gap: 38px;
							}


							.infoArea {
								@if $type =="" {
									display: flex;
								}

								@if $type ==sp {
									gap: 10px;
								}

								@if $type ==pc {
									gap: 24px;
								}

								.date {
									@if $type =="" {
										color: $c_base;
										letter-spacing: 0.06em;
										@include fontEn;
										font-weight: 900;
										font-style: italic;
									}

									@if $type ==sp {
										font-size: 11px;
										letter-spacing: 0.15em;
									}
								}

								.cat {
									color: #fff;
									display: flex;
									justify-content: center;
									align-items: center;

									@if $type ==sp {
										font-size: 10px;
										padding: 4px 5px;
										height: 20px;
									}

									@if $type ==pc {
										font-size: 11px;
										width: 126px;
										height: 30px;
									}
								}

							}

							.title {
								@if $type =="" {
									font-weight: 500;
								}

								@if $type ==sp {
									margin-top: 4px;
									font-size: 13px;
									letter-spacing: 0.015em;
								}

								@if $type ==pc {
									font-size: 16px;
								}
							}
						}

						.c-arrow {
							@if $type =="" {
								border-radius: 50%;
								visibility: hidden;
							}

							@if $type ==sp {
								display: block;
								width: 20px;
								height: 20px;
								position: relative;
							}

							@if $type ==pc {
								position: absolute;
								width: 27px;
								height: 27px;
								top: 50%;
								right: 20px;
								transform: translateY(-50%);
								display: inline-block;
								// transition: 0.2s all ease-in-out;
							}

							&::before {
								@if $type ==sp {
									content: '';
									width: 20px;
									height: 20px;
									background: $c_base;
									border-radius: 50%;
									position: absolute;
									margin: auto;
								}

								@if $type ==pc {
									content: '';
									width: 27px;
									height: 27px;
									background: $c_base;
									border-radius: 50%;
									position: absolute;
								}
							}

							&::after {
								@if $type =="" {
									content: '';
									position: absolute;
									background: url(../images/common/arrow-white.svg);
									background-repeat: no-repeat;
									background-size: contain;
									aspect-ratio: 83/150;
									left: 50%;
									top: 50%;
									transform: translate(-50%, -50%);
								}


								@if $type ==sp {
									width: 4px;
									height: auto;
								}

								@if $type ==pc {
									width: 6px;
									height: auto;
									left: calc(50% + 1px);
								}
							}
						}

						&:hover,
						&:focus {
							box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
							opacity: 0.7;

							.c-arrow {
								visibility: visible;
							}

							&::before {
								@if $type ==pc {
									visibility: hidden;
								}
							}
						}
					}


				}
			}
		}
	}
}

// serviceセクション
// #service {
// 	@if $type =="" {
// 		position: relative;
// 	}

// 	@if $type ==sp {
// 		padding-top: 96px;
// 		padding-bottom: 76px;
// 	}

// 	@if $type ==pc {
// 		padding-top: 160px;
// 		padding-bottom: 148px;
// 	}

// 	&::before {
// 		content: "";
// 		position: absolute;
// 		background: url(../images/home/service-bottom_bg.png);
// 		background-repeat: no-repeat;
// 		background-size: cover;
// 		height: 400px;
// 		left: 0;
// 		width: 100%;
// 		bottom: 0;
// 	}

// 	&::after {
// 		content: "";
// 		position: absolute;
// 		background: url(../images/home/rec-right-img.png);
// 		background-repeat: no-repeat;
// 		background-position: right;
// 		right: 0;
// 		top: 574px;
// 		width: 448px;
// 		height: 741px;
// 		background-size: contain;
// 	}


// 	.inner {
// 		@if $type =="" {
// 			position: relative;
// 			z-index: 2;
// 		}

// 		.wrapper {
// 			@if $type ==pc {
// 				display: flex;
// 				position: relative;
// 				justify-content: space-between;
// 			}

// 			.container {
// 				@if $type ==sp {
// 					margin-top: 36px;
// 				}

// 				.s-box {
// 					.serviceTitle {
// 						@if $type =="" {
// 							letter-spacing: 0;
// 							position: relative;
// 						}

// 						@if $type ==sp {
// 							font-size: 24px;
// 							padding-left: 50px;
// 							margin-top: 16px;
// 						}

// 						@if $type ==pc {
// 							font-size: 38px;
// 							padding-left: 70px;
// 						}

// 						&.book {
// 							&::before {
// 								@if $type =="" {
// 									content: "";
// 									position: absolute;
// 									background: url(../images/home/book-icon.png);
// 									background-size: contain;
// 									background-repeat: no-repeat;
// 									left: 0;
// 									top: 50%;
// 									transform: translateY(-50%);
// 									width: 37px;
// 									height: 43px;
// 								}

// 								@if $type ==sp {
// 									max-width: calc(55px/2);
// 									width: 100%;
// 									height: auto;
// 									aspect-ratio: 55/65;
// 								}
// 							}

// 						}

// 						&.frasko {
// 							&::before {
// 								@if $type =="" {
// 									content: "";
// 									position: absolute;
// 									background: url(../images/home/frasko-icon.png);
// 									background-size: contain;
// 									background-repeat: no-repeat;
// 									left: 0;
// 									top: 50%;
// 									transform: translateY(-50%);
// 									width: 37px;
// 									height: 43px;
// 								}

// 								@if $type ==sp {
// 									max-width: 31px;
// 									width: 100%;
// 									height: auto;
// 									aspect-ratio: 62/68;
// 								}
// 							}

// 						}

// 						&.lawyer {
// 							&::before {
// 								@if $type =="" {
// 									content: "";
// 									position: absolute;
// 									background: url(../images/home/lawyer-icon.png);
// 									background-size: contain;
// 									background-repeat: no-repeat;
// 									left: 0;
// 									top: 50%;
// 									transform: translateY(-50%);
// 									width: 37px;
// 									height: 43px;
// 								}

// 								@if $type ==sp {
// 									max-width: 32px;
// 									width: 100%;
// 									height: auto;
// 									aspect-ratio: 64/52;
// 								}
// 							}

// 						}

// 						&.doctor {
// 							&::before {
// 								@if $type =="" {
// 									content: "";
// 									position: absolute;
// 									background: url(../images/home/doctor-icon.png);
// 									background-size: contain;
// 									background-repeat: no-repeat;
// 									left: 0;
// 									top: 50%;
// 									transform: translateY(-50%);

// 								}

// 								@if $type ==sp {
// 									max-width: 27px;
// 									width: 100%;
// 									height: auto;
// 									aspect-ratio: 54/72;
// 								}

// 								@if $type ==pc {
// 									width: 37px;
// 									height: 43px;
// 								}
// 							}

// 						}
// 					}

// 					.serviceText {
// 						@if $type =="" {
// 							font-weight: 600;
// 						}

// 						@if $type ==sp {
// 							font-size: 14px;
// 							line-height: 1.85;
// 						}

// 						@if $type ==pc {
// 							line-height: 1.93;
// 							margin-top: 30px;
// 						}
// 					}

// 					&+.serviceItem {
// 						@if $type ==pc {
// 							margin-top: 170px;
// 						}
// 					}

// 					.btn {
// 						@if $type ==sp {
// 							margin-left: auto;
// 							margin-top: 22px;
// 							width: 225px;
// 						}

// 						@if $type ==pc {
// 							margin-top: 20px;
// 						}
// 					}

// 					.serviceImg {

// 						@if $type ==sp {
// 							width: 100vw;
// 							margin-right: calc(50% - 50vw);
// 						}

// 					}

// 					&+.s-box {
// 						@if $type ==sp {
// 							margin-top: 36px;
// 						}
// 					}
// 				}



// 			}
// 		}

// 	}
// }

// processセクション
.process {
	@if $type =="" {}

	@if $type ==sp {
		padding-top: 68px;
		padding-bottom: 64px;
		background: url(../images/home/process-bg_sp.png);
		background-size: cover;
		background-position: center;
	}

	@if $type ==pc {
		padding-top: 94px;
		padding-bottom: 100px;
		background: url(../images/home/process-bg.png);
		background-size: cover;
		background-position: center;
		margin-top: -344px;
	}

	.inner {
		.homeH3 {
			@if $type =="" {
				text-align: center;

			}

			@if $type ==pc {
				font-size: 28px;
			}

			>span {
				@if $type ==sp {
					font-size: 16px;
				}

				@if $type ==pc {
					font-size: 22px;
				}
			}
		}

		.btnWrap {
			@if $type =="" {
				text-align: center;
			}

			@if $type ==pc {
				margin-top: 40px;
			}

			a {
				@if $type =="" {
					text-align: center;
					font-weight: 500;
					margin-inline: auto;
				}

				@if $type ==sp {
					font-size: 14px;
					text-align: left;
					line-height: 1.6;
					padding-left: 30px;
					padding-right: 40px;
					max-width: unset;
					min-width: unset;
				}

				@if $type ==pc {
					font-size: 26px;
					max-width: 682px;
					width: 100%;
					padding-block: 30px;
					padding-left: 50px;
					justify-content: space-between;
				}

				.c-arrow {
					@if $type ==sp {
						height: 20px;
						width: 20px;

						&::before {
							height: 20px;
							width: 20px;
						}
					}

					@if $type ==pc {
						height: 38px;
						width: 38px;

						&::before {
							height: 38px;
							width: 38px;
						}
					}
				}

				&:hover,
				&:focus {
					@if $type ==pc {
						opacity: 0.8;
					}
				}
			}
		}

	}

	.btn {
		@if $type ==sp {
			max-width: 238px;
			margin-top: 45px;
		}

		@if $type ==pc {}
	}
}


#service {
	@if $type =="" {
		position: relative;
	}

	@if $type ==sp {
		margin-top: 62px;
		padding-bottom: 76px;
		overflow-x: hidden;
	}

	@if $type ==pc {
		padding-top: 160px;
		padding-bottom: 50px;
		margin-inline: auto;
	}

	&::before {
		content: "";
		position: absolute;
		background: url(../images/home/service-bottom_bg.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 433px;
		left: 0;
		width: 100%;
		bottom: 340px;

		@if $type ==sp {
			bottom: 0;
		}
	}

	&::after {
		@if $type ==pc {
			content: "";
			position: fixed;
			background: url(../images/home/rec-right-img.png);
			background-repeat: no-repeat;
			background-position: right;
			right: 0;
			top: 574px;
			width: 448px;
			height: 741px;
			background-size: contain;
			z-index: -1;
		}

	}

	.inner {

		.hasRec {
			@if $type =="" {
				position: absolute;
				left: 0;
				width: 18.1vw;
				top: 0;
				aspect-ratio: 585/711;
			}

			&::before {
				@if $type ==pc {
					content: "";
					background: url(../images/home/service-rec.png);
					position: absolute;
					background-repeat: no-repeat;
					top: 106px;
					left: 0;
					aspect-ratio: 585/711;
					width: 18.1vw;
					height: 100%;
					background-size: contain;
				}
			}
		}


		.wrap {
			@if $type ==pc {
				width: 100%;
				margin: 0 auto;
				padding: 100px 0;
				// display: flex;
				// justify-content: space-between;
				position: relative;
				// max-width: 1290px;
				// gap: 8.1vw;
			}

			.container {
				@if $type ==sp {
					margin-top: 32px;
				}

				@if $type ==pc {
					width: 40.9%;
					// margin-top: -880px;
					margin-top: -100vh;
				}

				>div {
					@if $type ==pc {
						padding-top: 10vw;
						padding-bottom: 15vw;

						// padding-bottom: 25vw;
						// padding-top: 25vw;		
					}


					&+div {
						@if $type ==sp {
							padding-top: 64px;
						}

						@if $type ==pc {}
					}

					p {
						@if $type ==sp {
							margin-top: 8px;
						}

						@if $type ==pc {
							margin-top: 30px;
						}

						text-align: left;
						line-height: 170%;
					}


					.serviceTitle {
						@if $type =="" {
							letter-spacing: 0;
							position: relative;
							text-align: left;
							font-weight: 600;
						}

						@if $type ==sp {
							font-size: 24px;
							padding-left: 50px;
							margin-top: 16px;
						}

						@if $type ==pc {
							font-size: 38px;
							padding-left: 70px;
							padding-top: 0;
						}

						&.book {
							&::before {
								@if $type =="" {
									content: "";
									position: absolute;
									background: url(../images/home/book-icon.png);
									background-size: contain;
									background-repeat: no-repeat;
									left: 0;
									top: 50%;
									transform: translateY(-50%);
									width: 37px;
									height: 43px;
								}

								@if $type ==sp {
									max-width: calc(55px/2);
									width: 100%;
									height: auto;
									aspect-ratio: 55/65;
								}
							}

						}

						&.frasko {
							&::before {
								@if $type =="" {
									content: "";
									position: absolute;
									background: url(../images/home/frasko-icon.png);
									background-size: contain;
									background-repeat: no-repeat;
									left: 0;
									top: 50%;
									transform: translateY(-50%);
									width: 37px;
									height: 43px;
								}

								@if $type ==sp {
									max-width: 31px;
									width: 100%;
									height: auto;
									aspect-ratio: 62/68;
								}
							}

						}

						&.lawyer {
							&::before {
								@if $type =="" {
									content: "";
									position: absolute;
									background: url(../images/home/lawyer-icon.png);
									background-size: contain;
									background-repeat: no-repeat;
									left: 0;
									top: 50%;
									transform: translateY(-50%);
									width: 100%;
									max-width: 53px;
									height: auto;
									aspect-ratio: 46/37;
								}

								@if $type ==sp {
									max-width: 32px;
									width: 100%;
									height: auto;
									aspect-ratio: 64/52;
								}
							}

						}

						&.doctor {
							&::before {
								@if $type =="" {
									content: "";
									position: absolute;
									background: url(../images/home/doctor-icon.png);
									background-size: contain;
									background-repeat: no-repeat;
									left: 0;
									top: 50%;
									transform: translateY(-50%);

								}

								@if $type ==sp {
									max-width: 27px;
									width: 100%;
									height: auto;
									aspect-ratio: 54/72;
								}

								@if $type ==pc {
									width: 37px;
									height: 43px;
								}
							}

						}
					}

					.serviceText {
						@if $type =="" {
							font-weight: 600;
							position: relative;

						}

						@if $type ==sp {
							font-size: 14px;
							line-height: 1.85;
							z-index: 10;
						}

						@if $type ==pc {
							line-height: 1.93;
							margin-top: 30px;
						}
					}

					&+.serviceItem {
						@if $type ==pc {
							margin-top: 170px;
						}
					}

					.btnWrap {
						@if $type ==sp {
							margin-top: 22px;
							text-align: right;
						}

						@if $type ==pc {
							margin-top: 20px;
						}

						.btn {
							@if $type ==sp {
								width: 220px;
								text-align: left;
								min-width: unset;
							}

						}
					}

					.serviceImg {

						@if $type ==sp {
							width: 100vw;
							margin-right: calc(50% - 50vw);
						}

					}

					ul {
						li {
							a {
								@if $type =="" {
									font-weight: 600;
									display: block;
									position: relative;
								}

								@if $type ==sp {
									font-size: 17px;
									padding-block: 14px;
									align-items: center;
									padding-right: 30px;
								}

								@if $type ==pc {
									font-size: 21px;
									padding-block: 20px;
									align-items: center;
									gap: 30px;
									display: flex;
									padding-right: 40px;
									transition: all 0.3s;
								}

								&:hover,
								&:focus {
									@if $type ==pc {
										opacity: 0.7;
									}
								}

								.mini {
									@if $type ==pc {
										font-size: 20px;
										display: inline;
									}
								}

								.c-arrow {
									@if $type =="" {
										position: absolute;
									}

									@if $type ==sp {
										top: 50%;
										transform: translateY(-50%);
										width: 16px;
										height: 16px;
										right: 0;
									}

									@if $type ==pc {
										width: 27px;
										height: 27px;
										right: 0;
										top: 50%;
										transform: translateY(-50%);
									}

									&::before {
										@if $type ==sp {
											content: '';
											width: 16px;
											height: 16px;
											background: $c_base;
											border-radius: 50%;
											position: absolute;
											top: 0;
											left: 0;
											bottom: 0;
											margin: auto;
											right: 0;
										}

										@if $type ==pc {
											content: '';
											width: 27px;
											height: 27px;
											background: $c_base;
											border-radius: 50%;
											position: absolute;
											top: 0;
											left: 0px;
											bottom: 0;
											margin: auto;
											right: 0;
										}
									}

									&::after {
										@if $type =="" {
											position: absolute;
											content: "";
											background: url(../images/common/arrow-white.svg);
											background-size: contain;
											background-repeat: no-repeat;
											aspect-ratio: 83/150;

											transform: translate(-50%, -50%);
										}

										@if $type ==sp {
											width: 4px;
											height: auto;
											top: 50%;
											left: 50%;

										}

										@if $type ==pc {
											width: 6px;
											height: auto;
											top: calc(50% + 0px);
											left: calc(50% + 1px);
										}
									}

									&.black {
										&::before {
											background: #fff;
										}

										&::after {
											background: url(../images/common/arrow-lightgray.svg);
											background-size: contain;
											background-repeat: no-repeat;
											aspect-ratio: 83/150;
										}
									}

									&.orange {
										&::before {
											background: #fff;
										}

										&::after {
											background: url(../images/common/arrow-orange.svg);
											background-size: contain;
											background-repeat: no-repeat;
											aspect-ratio: 83/150;
										}
									}

									&.lightgray {
										&::before {
											background: #4c4c4c;
										}


									}

									&.skyblue {
										&::before {
											background: #2b91c1;
										}


									}

									&.blue {
										&::before {
											background: #3363ab;
										}


									}
								}
							}

							a:has(.mini) {
								@if $type ==pc {
									display: block;
								}
							}

							&:not(:first-child) {
								a {
									border-top: 1px solid #202022;
								}
							}

							&:last-child {
								a {
									@if $type ==sp {
										border-bottom: 1px solid #202022;
									}
								}
							}
						}
					}

					.serviceImg {
						@if $type ==sp {
							width: 100vw;
							margin-right: calc(50% - 50vw);
						}

					}

					&.scroll-area1 {
						@if $type ==pc {
							padding-top: 0;
						}
					}
				}

				div:last-child {
					@if $type ==pc {
						padding-bottom: 25vh;
					}

				}

				.scrollBox+.scrollBox {
					@if $type ==pc {
						margin-top: 180px;
					}
				}
			}

			.images-container {
				@if $type ==pc {
					width: 46.1vw;
					height: 100vh;
					align-items: center;
					position: sticky;
					position: -webkit-sticky;
					max-width: 740px;
					// top: 220px;
					top: 25vh;
					margin-left: min(46.3vw, 550px);

					p:first-of-type {
						opacity: 1 !important;
						visibility: visible !important;
					}
				}

				img {
					@if $type ==pc {
						max-width: 100%;
						height: auto;
						aspect-ratio: 724/524;
					}

				}

				p {


					@if $type ==pc {
						// height: 650px;
						margin: auto;
						display: block;
						visibility: hidden;
						position: absolute;
						// top: 0;
						// bottom: 0;
						transition: .8s !important;
						opacity: 0;
						aspect-ratio: 724/524;
						width: 44.3vw;
						max-width: 738px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.active {
						visibility: visible;
						opacity: 1;
					}
				}

			}
		}
	}
}


#spFixedContact {
	@if $type ==sp {
		position: fixed;
		width: 100%;
		z-index: 20;
		padding: 20px;
		background: #fff;
		overflow: hidden;
		transform: translateY(100%);
		transition: transform 0.3s;
		bottom: 0;

		&.visible {
			transform: translateY(0%)
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;

			width: 100%;
			background: #f3961e;
			color: #fff;
			font-size: 18px;
			font-weight: 600;
			gap: 20px;
			padding: 10px;
			border-radius: 10px;

			i {
				width: 30px;
				height: 30px;
				background: #fff;
				border-radius: 50px;
				aspect-ratio: 1/1;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 20px;
				}
			}
		}

	}
}