@charset "UTF-8";
/* --------------- variables --------------------- */
/* Break Point */
/* Color */
/*サイトカラー*/
/*サイトカラー暗め*/
/*テキストに使うフォントカラー*/
/*未設定*/
/*
.f_em{ @include fontUsu; }
.f_os{ @include fontOs; }
.f_min{ @include fontMin; }
.f_lc{ @include fontLc; }
*/
p {
  font-weight: 500;
}

a {
  text-decoration: none;
  color: #000;
}

.h2TitleWrap {
  color: #005b2a;
  position: relative;
}
.h2TitleWrap .en {
  font-weight: 900;
  font-family: "Lato", sans-serif;
  font-style: italic;
  position: relative;
}
.h2TitleWrap .en::before {
  content: "";
  position: absolute;
  background: #005b2a;
  left: 0;
  bottom: 10px;
  width: 100%;
  height: 3px;
}
.h2TitleWrap .homeH2 {
  font-weight: 600;
  letter-spacing: 0.06em;
}
.h2TitleWrap.hasRec::before {
  position: absolute;
  content: "";
  background: url(../images/home/rec-img.png);
  background-size: contain;
  background-repeat: no-repeat;
  top: 10px;
  left: -50px;
  width: 116px;
  height: 154px;
  aspect-ratio: 229/306;
  z-index: -1;
}

.homeH3 {
  font-weight: 600;
  letter-spacing: 0.06em;
  color: #000;
}

#fv {
  position: relative;
}
#fv .fvInner .fvTextWrap .fvCatch {
  position: relative;
  display: inline-block;
}
#fv .fvInner .fvTextWrap .fvText {
  position: relative;
}
#fv .fvInner .fvTextWrap .fvText .img::before {
  position: absolute;
  content: "";
  background: #005b2a;
  height: 3px;
  width: 100%;
  left: 0;
  bottom: 0;
}
#fv .fvNavWrap {
  display: flex;
}
#fv .fvNavWrap li {
  border-radius: 8px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
#fv .fvNavWrap li a {
  position: relative;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 0px 6px -4px #000;
}
#fv .fvNavWrap li a .num {
  position: absolute;
  top: 8px;
  color: #fff;
  font-family: "Jost", sans-serif;
  font-weight: normal;
}
#fv .fvNavWrap li a.green {
  background: #005b2a;
}
#fv .fvNavWrap li a.skyblue {
  background: #2b91c1;
}
#fv .fvNavWrap li a.blue {
  background: #3363ab;
}
#fv .fixedNav .title {
  background: #f3961e;
  color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#fv .fixedNav .title .mailIcon img {
  width: 100%;
}
#fv .fixedNav ul li a {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 6px -4px #000;
  font-weight: 60;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 30px;
  padding-right: 28px;
}
#fv .fixedNav ul li a .navTitle {
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.025em;
  display: block;
}
#fv .fixedNav ul li a .telNum {
  font-weight: 600;
  display: block;
  font-family: "Jost", sans-serif;
  letter-spacing: 0.05em;
}
#fv .fixedNav ul li a.doctorIcon .telNum {
  color: #2b91c1;
}
#fv .fixedNav ul li a.fraskoIcon .telNum {
  color: #3363ab;
}
#fv .fixedNav ul li a .phoneCircle {
  background: #f3961e;
}
#fv .fixedNav ul li a .phoneCircle img {
  max-width: 14px;
  width: 100%;
}
#fv .fixedNav ul li a .doctorCircle {
  background: #2b91c1;
}
#fv .fixedNav ul li a .doctorCircle img {
  max-width: 17px;
  width: 100%;
}
#fv .fixedNav ul li a .fraskoCircle {
  background: #3363ab;
}
#fv .fixedNav ul li a .fraskoCircle img {
  max-width: 17px;
  width: 100%;
}
#fv .fixedNav .info {
  line-height: 1.25;
  letter-spacing: 0.05em;
  text-align: center;
}

.slideIn {
  opacity: 0;
  animation: slideInAnime 0.45s cubic-bezier(0.25, 0.1, 0.25, 1) both;
}

@keyframes slideInAnime {
  0% {
    opacity: 0;
    transform: translateY(-150px);
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
.slideInNext {
  opacity: 0;
  animation: slideInAnime2 0.45s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s both;
}

@keyframes slideInAnime2 {
  0% {
    transform: translateY(-150px);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  opacity: 0;
  animation-delay: 2s;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeUp {
  animation: fadeUpAnime 0.4s ease-out forwards;
  animation-delay: 1s;
}

@keyframes fadeUpAnime {
  to {
    transform: translateY(0%);
    transition: transform 1s 0s cubic-bezier(0.25, 0, 0, 1);
  }
}
#about {
  position: relative;
  overflow: hidden;
}
#about .inner {
  position: relative;
  z-index: 5;
}
#about .inner .aboutCatch img {
  aspect-ratio: 930/250;
}
#about .inner .btnWrap {
  text-align: right;
}
#about .inner .btnWrap a {
  color: #fff;
  background: #005b2a;
}
#about .inner .btnWrap a .c-arrow::before {
  background: #fff;
}
#about .inner .btnWrap a .c-arrow::after {
  position: absolute;
  content: "";
  background: url(../images/common/arrow-green.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
}
#about .inner .newsWrap {
  margin-inline: auto;
}
#about .inner .newsWrap .newsLeft .titleWrap {
  display: flex;
}
#about .inner .newsWrap .newsLeft .titleWrap .en {
  font-family: "Lato", sans-serif;
  color: #005b2a;
  font-weight: 700;
  font-style: italic;
}
#about .inner .newsWrap .newsLeft .titleWrap .title {
  color: #005b2a;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0.06em;
}
#about .inner .newsWrap .newsLeft .titleWrap + a {
  color: #005b2a;
  background: #fff;
}
#about .inner .newsWrap .newsLeft .titleWrap + a .c-arrow::after {
  position: absolute;
  content: "";
  background: url(../images/common/arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
}
#about .inner .newsWrap .newsListArea .newsItem a {
  display: flex;
  position: relative;
  display: block;
}
#about .inner .newsWrap .newsListArea .newsItem a .textArea {
  display: flex;
}
#about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea {
  display: flex;
}
#about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea .date {
  color: #005b2a;
  letter-spacing: 0.06em;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}
#about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea .cat {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
#about .inner .newsWrap .newsListArea .newsItem a .textArea .title {
  font-weight: 500;
}
#about .inner .newsWrap .newsListArea .newsItem a .c-arrow {
  border-radius: 50%;
  visibility: hidden;
}
#about .inner .newsWrap .newsListArea .newsItem a .c-arrow::after {
  content: "";
  position: absolute;
  background: url(../images/common/arrow-white.svg);
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 83/150;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
#about .inner .newsWrap .newsListArea .newsItem a:hover, #about .inner .newsWrap .newsListArea .newsItem a:focus {
  box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
  opacity: 0.7;
}
#about .inner .newsWrap .newsListArea .newsItem a:hover .c-arrow, #about .inner .newsWrap .newsListArea .newsItem a:focus .c-arrow {
  visibility: visible;
}
.process .inner .homeH3 {
  text-align: center;
}
.process .inner .btnWrap {
  text-align: center;
}
.process .inner .btnWrap a {
  text-align: center;
  font-weight: 500;
  margin-inline: auto;
}
#service {
  position: relative;
}
#service::before {
  content: "";
  position: absolute;
  background: url(../images/home/service-bottom_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 433px;
  left: 0;
  width: 100%;
  bottom: 340px;
}
#service .inner .hasRec {
  position: absolute;
  left: 0;
  width: 18.1vw;
  top: 0;
  aspect-ratio: 585/711;
}
#service .inner .wrap .container > div p {
  text-align: left;
  line-height: 170%;
}
#service .inner .wrap .container > div .serviceTitle {
  letter-spacing: 0;
  position: relative;
  text-align: left;
  font-weight: 600;
}
#service .inner .wrap .container > div .serviceTitle.book::before {
  content: "";
  position: absolute;
  background: url(../images/home/book-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 37px;
  height: 43px;
}
#service .inner .wrap .container > div .serviceTitle.frasko::before {
  content: "";
  position: absolute;
  background: url(../images/home/frasko-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 37px;
  height: 43px;
}
#service .inner .wrap .container > div .serviceTitle.lawyer::before {
  content: "";
  position: absolute;
  background: url(../images/home/lawyer-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 53px;
  height: auto;
  aspect-ratio: 46/37;
}
#service .inner .wrap .container > div .serviceTitle.doctor::before {
  content: "";
  position: absolute;
  background: url(../images/home/doctor-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
#service .inner .wrap .container > div .serviceText {
  font-weight: 600;
  position: relative;
}
#service .inner .wrap .container > div ul li a {
  font-weight: 600;
  display: block;
  position: relative;
}
#service .inner .wrap .container > div ul li a .c-arrow {
  position: absolute;
}
#service .inner .wrap .container > div ul li a .c-arrow::after {
  position: absolute;
  content: "";
  background: url(../images/common/arrow-white.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
  transform: translate(-50%, -50%);
}
#service .inner .wrap .container > div ul li a .c-arrow.black::before {
  background: #fff;
}
#service .inner .wrap .container > div ul li a .c-arrow.black::after {
  background: url(../images/common/arrow-lightgray.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
}
#service .inner .wrap .container > div ul li a .c-arrow.orange::before {
  background: #fff;
}
#service .inner .wrap .container > div ul li a .c-arrow.orange::after {
  background: url(../images/common/arrow-orange.svg);
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 83/150;
}
#service .inner .wrap .container > div ul li a .c-arrow.lightgray::before {
  background: #4c4c4c;
}
#service .inner .wrap .container > div ul li a .c-arrow.skyblue::before {
  background: #2b91c1;
}
#service .inner .wrap .container > div ul li a .c-arrow.blue::before {
  background: #3363ab;
}
#service .inner .wrap .container > div ul li:not(:first-child) a {
  border-top: 1px solid #202022;
}
#service .inner .wrap .images-container p.active {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  p {
    font-size: 14px;
  }
  .inner {
    padding-inline: 20px;
  }
  #ghMenuBtn {
    top: 10px;
  }
  .h2TitleWrap {
    color: #005b2a;
    position: relative;
  }
  .h2TitleWrap .en {
    font-size: 27.5px;
  }
  .h2TitleWrap .en::before {
    bottom: 4px;
  }
  .h2TitleWrap .homeH2 {
    font-size: 19px;
    line-height: 1;
  }
  .h2TitleWrap.hasRec::before {
    position: absolute;
    content: "";
    background: url(../images/home/rec-img.png);
    background-size: contain;
    background-repeat: no-repeat;
    top: 10px;
    left: -50px;
    width: 116px;
    height: 154px;
    aspect-ratio: 229/306;
    z-index: -1;
    width: 14.9vw;
    background-repeat: no-repeat;
    left: 0;
  }
  .homeH3 {
    font-size: 20px;
  }
  #fv {
    background: url(../images/home/fv-bg_sp.png);
    background-position: center top 0px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 115px;
  }
  #fv .fvInner .fvTextWrap {
    padding-left: 20px;
  }
  #fv .fvInner .fvTextWrap .fvCatch {
    max-width: 243px;
    width: 100%;
  }
  #fv .fvInner .fvTextWrap .fvText {
    max-width: 167px;
    margin-top: 16px;
    width: 100%;
    line-height: 1;
  }
  #fv .fvInner .fvTextWrap .fvText .img::before {
    height: 1px;
    bottom: 0px;
  }
  #fv .fvInner .fvImgWrap {
    margin-top: 18px;
  }
  #fv .fvInner .fvImgWrap .mainImg {
    width: 73.3vw;
  }
  #fv .fvInner .fvImgWrap .imgRight {
    position: absolute;
    right: 0;
    top: 165px;
  }
  #fv .fvInner .fvImgWrap .imgRight .mainImg {
    width: 52vw;
  }
  #fv .fvNavWrap {
    gap: 4px;
    width: calc(100% - 20px);
    margin-inline: auto;
    margin-top: 20px;
  }
  #fv .fvNavWrap li {
    width: calc((100% - 8px) / 3);
  }
  #fv .fvNavWrap li a {
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    letter-spacing: -0.025em;
    line-height: 1.25;
    text-align: center;
    padding: 8px;
    min-height: 105px;
    align-items: center;
  }
  #fv .fvNavWrap li a .num {
    font-size: 12px;
    left: 10px;
  }
  #fv .fvNavWrap li a .icon .lawyer {
    max-width: 26px;
    width: 100%;
  }
  #fv .fvNavWrap li a .icon .doctor {
    max-width: 20px;
    width: 100%;
  }
  #fv .fvNavWrap li a .icon .frasko {
    max-width: 19px;
    width: 100%;
  }
  #fv .fvNavWrap li a.green {
    background: #005b2a;
  }
  #fv .fvNavWrap li a.skyblue {
    background: #2b91c1;
  }
  #fv .fvNavWrap li a.blue {
    background: #3363ab;
  }
  #fv .fixedNav .title .mailIcon img {
    width: 100%;
  }
  #fv .fixedNav ul li a.doctorIcon .telNum {
    color: #2b91c1;
  }
  #fv .fixedNav ul li a.fraskoIcon .telNum {
    color: #3363ab;
  }
  .slideIn {
    opacity: 0;
    animation: slideInAnime 0.45s cubic-bezier(0.25, 0.1, 0.25, 1) both;
  }
  @keyframes slideInAnime {
    0% {
      opacity: 0;
      transform: translateY(-150px);
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .slideInNext {
    opacity: 0;
    animation: slideInAnime2 0.45s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s both;
  }
  @keyframes slideInAnime2 {
    0% {
      transform: translateY(-150px);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .fadeIn {
    animation-name: fadeInAnime;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-delay: 2s;
  }
  @keyframes fadeInAnime {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadeUp {
    animation: fadeUpAnime 0.4s ease-out forwards;
    animation-delay: 1s;
  }
  @keyframes fadeUpAnime {
    to {
      transform: translateY(0%);
      transition: transform 1s 0s cubic-bezier(0.25, 0, 0, 1);
    }
  }
  #about {
    margin-top: 40px;
    padding-bottom: 35px;
  }
  #about::before {
    position: absolute;
    content: "";
    background: url(../images/home/about-bg_sp.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    left: 0;
    max-height: 891px;
    width: 100%;
    height: 100%;
  }
  #about .aboutSideImg {
    width: calc(100% + 40px);
    margin-inline: auto;
    margin-top: 60px;
    clip-path: polygon(0 0, 100% 0, 87% 100%, 0% 100%);
    margin-bottom: 60px;
    aspect-ratio: 750/461;
  }
  #about .inner {
    position: relative;
    z-index: 5;
  }
  #about .inner .aboutCatch {
    width: 65.3%;
    max-width: 415px;
    margin-top: 20px;
    position: relative;
    z-index: 10;
  }
  #about .inner .aboutCatch img {
    aspect-ratio: 930/250;
  }
  #about .inner .aboutText {
    margin-top: 14px;
  }
  #about .inner .btnWrap {
    margin-top: 24px;
  }
  #about .inner .btnWrap a {
    width: 190px;
    min-width: unset;
    padding-left: 22px;
    padding-right: 32px;
    font-size: 15px;
  }
  #about .inner .btnWrap a .c-arrow::before {
    background: #fff;
  }
  #about .inner .newsWrap .newsLeft {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #about .inner .newsWrap .newsLeft .titleWrap {
    align-items: baseline;
    gap: 12px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap .en {
    font-family: "Lato", sans-serif;
    color: #005b2a;
    font-weight: 700;
    font-style: italic;
    font-size: 24px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap .title {
    font-size: 14px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap + a {
    margin: 0;
    width: 97px;
    font-size: 9px;
    min-width: unset;
    padding-right: 26px;
    padding-left: 10px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap + a .c-arrow {
    width: 12px;
    height: 12px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap + a .c-arrow::before {
    width: 12px;
    height: 12px;
  }
  #about .inner .newsWrap .newsListArea .newsItem a {
    flex-direction: column;
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: solid 1px #aaa;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea {
    flex-direction: column;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea {
    gap: 10px;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea .date {
    font-size: 11px;
    letter-spacing: 0.15em;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea .cat {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    padding: 4px 5px;
    height: 20px;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea .title {
    margin-top: 4px;
    font-size: 13px;
    letter-spacing: 0.015em;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .c-arrow {
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .c-arrow::before {
    content: "";
    width: 20px;
    height: 20px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    margin: auto;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .c-arrow::after {
    width: 4px;
    height: auto;
  }
  #about .inner .newsWrap .newsListArea .newsItem a:hover, #about .inner .newsWrap .newsListArea .newsItem a:focus {
    box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
    opacity: 0.7;
  }
  #about .inner .newsWrap .newsListArea .newsItem a:hover .c-arrow, #about .inner .newsWrap .newsListArea .newsItem a:focus .c-arrow {
    visibility: visible;
  }
  .process {
    padding-top: 68px;
    padding-bottom: 64px;
    background: url(../images/home/process-bg_sp.png);
    background-size: cover;
    background-position: center;
  }
  .process .inner .homeH3 > span {
    font-size: 16px;
  }
  .process .inner .btnWrap a {
    font-size: 14px;
    text-align: left;
    line-height: 1.6;
    padding-left: 30px;
    padding-right: 40px;
    max-width: unset;
    min-width: unset;
  }
  .process .inner .btnWrap a .c-arrow {
    height: 20px;
    width: 20px;
  }
  .process .inner .btnWrap a .c-arrow::before {
    height: 20px;
    width: 20px;
  }
  .process .btn {
    max-width: 238px;
    margin-top: 45px;
  }
  #service {
    margin-top: 62px;
    padding-bottom: 76px;
    overflow-x: hidden;
  }
  #service::before {
    content: "";
    position: absolute;
    background: url(../images/home/service-bottom_bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 433px;
    left: 0;
    width: 100%;
    bottom: 340px;
    bottom: 0;
  }
  #service .inner .wrap .container {
    margin-top: 32px;
  }
  #service .inner .wrap .container > div + div {
    padding-top: 64px;
  }
  #service .inner .wrap .container > div p {
    margin-top: 8px;
    text-align: left;
    line-height: 170%;
  }
  #service .inner .wrap .container > div .serviceTitle {
    font-size: 24px;
    padding-left: 50px;
    margin-top: 16px;
  }
  #service .inner .wrap .container > div .serviceTitle.book::before {
    max-width: 27.5px;
    width: 100%;
    height: auto;
    aspect-ratio: 55/65;
  }
  #service .inner .wrap .container > div .serviceTitle.frasko::before {
    max-width: 31px;
    width: 100%;
    height: auto;
    aspect-ratio: 62/68;
  }
  #service .inner .wrap .container > div .serviceTitle.lawyer::before {
    max-width: 32px;
    width: 100%;
    height: auto;
    aspect-ratio: 64/52;
  }
  #service .inner .wrap .container > div .serviceTitle.doctor::before {
    max-width: 27px;
    width: 100%;
    height: auto;
    aspect-ratio: 54/72;
  }
  #service .inner .wrap .container > div .serviceText {
    font-size: 14px;
    line-height: 1.85;
    z-index: 10;
  }
  #service .inner .wrap .container > div .btnWrap {
    margin-top: 22px;
    text-align: right;
  }
  #service .inner .wrap .container > div .btnWrap .btn {
    width: 220px;
    text-align: left;
    min-width: unset;
  }
  #service .inner .wrap .container > div .serviceImg {
    width: 100vw;
    margin-right: calc(50% - 50vw);
  }
  #service .inner .wrap .container > div ul li a {
    font-size: 17px;
    padding-block: 14px;
    align-items: center;
    padding-right: 30px;
  }
  #service .inner .wrap .container > div ul li a .c-arrow {
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    right: 0;
  }
  #service .inner .wrap .container > div ul li a .c-arrow::before {
    content: "";
    width: 16px;
    height: 16px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    right: 0;
  }
  #service .inner .wrap .container > div ul li a .c-arrow::after {
    width: 4px;
    height: auto;
    top: 50%;
    left: 50%;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.black::before {
    background: #fff;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.black::after {
    background: url(../images/common/arrow-lightgray.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.orange::before {
    background: #fff;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.lightgray::before {
    background: #4c4c4c;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.skyblue::before {
    background: #2b91c1;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.blue::before {
    background: #3363ab;
  }
  #service .inner .wrap .container > div ul li:not(:first-child) a {
    border-top: 1px solid #202022;
  }
  #service .inner .wrap .container > div ul li:last-child a {
    border-bottom: 1px solid #202022;
  }
  #service .inner .wrap .container > div .serviceImg {
    width: 100vw;
    margin-right: calc(50% - 50vw);
  }
  #service .inner .wrap .images-container p.active {
    visibility: visible;
    opacity: 1;
  }
  #spFixedContact {
    position: fixed;
    width: 100%;
    z-index: 20;
    padding: 20px;
    background: #fff;
    overflow: hidden;
    transform: translateY(100%);
    transition: transform 0.3s;
    bottom: 0;
  }
  #spFixedContact.visible {
    transform: translateY(0%);
  }
  #spFixedContact a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #f3961e;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    gap: 20px;
    padding: 10px;
    border-radius: 10px;
  }
  #spFixedContact a i {
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50px;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #spFixedContact a i img {
    width: 20px;
  }
}
@media print, screen and (min-width: 769px) {
  p {
    font-size: 18px;
  }
  .section + .section {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .inner {
    max-width: 1102px;
    width: 90%;
    margin: 0 auto;
  }
  .h2TitleWrap {
    color: #005b2a;
    position: relative;
  }
  .h2TitleWrap .en {
    font-size: 55px;
  }
  .h2TitleWrap .homeH2 {
    font-size: 28px;
  }
  .h2TitleWrap.hasRec::before {
    position: absolute;
    content: "";
    background: url(../images/home/rec-img.png);
    background-size: contain;
    background-repeat: no-repeat;
    top: 10px;
    left: -50px;
    width: 116px;
    height: 154px;
    aspect-ratio: 229/306;
    z-index: -1;
  }
  .homeH3 {
    font-size: 38px;
  }
  #fv {
    background: url(../images/home/fv-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 700px;
    padding-top: 303px;
    padding-bottom: 75px;
  }
  #fv .fvInner {
    margin-inline: auto;
    max-width: calc(100% - 80px);
    width: 90%;
  }
  #fv .fvInner .fvTextWrap .fvCatch {
    max-width: 512.5px;
    width: 32vw;
  }
  #fv .fvInner .fvTextWrap .fvText {
    max-width: 435px;
    margin-top: 50px;
    width: 27.5vw;
  }
  #fv .fvNavWrap {
    max-width: 830px;
    width: 51.8vw;
    gap: 14px;
    margin-top: min(14.8vw, 238px);
    position: relative;
    z-index: 10;
  }
  #fv .fvNavWrap li {
    width: calc((100% - 20px) / 3);
  }
  #fv .fvNavWrap li a {
    padding-block: min(1.25vw, 20px);
    font-size: min(1.43vw, 23px);
    gap: 20px;
    line-height: 1.21;
    min-height: 100px;
  }
  #fv .fvNavWrap li a:hover, #fv .fvNavWrap li a:focus {
    transition: all 0.3s;
    opacity: 0.9;
  }
  #fv .fvNavWrap li a .num {
    font-size: min(1.12vw, 18px);
    left: 16px;
  }
  #fv .fvNavWrap li a .icon .lawyer {
    max-width: 46px;
    width: 100%;
    width: min(2.87vw, 46px);
  }
  #fv .fvNavWrap li a .icon .doctor {
    width: min(2.25vw, 36px);
  }
  #fv .fvNavWrap li a .icon .frasko {
    max-width: 33px;
    width: min(2.06vw, 33px);
  }
  #fv .fvNavWrap li a.green {
    background: #005b2a;
  }
  #fv .fvNavWrap li a.skyblue {
    background: #2b91c1;
  }
  #fv .fvNavWrap li a.blue {
    background: #3363ab;
  }
  #fv .fvImgWrap {
    position: absolute;
    right: 0;
    display: flex;
    width: 74.6vw;
    top: 160px;
    height: auto;
    max-height: 736px;
    aspect-ratio: 736/1018;
  }
  #fv .fvImgWrap > .mainImg {
    position: relative;
    left: 10.8vw;
  }
  #fv .fvImgWrap .imgRight .mainImg + .mainImg {
    margin-top: 3.1vw;
  }
  #fv .fixedNav {
    position: absolute;
    right: 40px;
    background: rgba(255, 255, 255, 0.8);
    top: 50%;
    padding: 24px 20px 20px;
    border-radius: 12px;
    max-width: 320px;
    display: block;
    box-shadow: 0px 0px 6px -4px #000;
    width: 100%;
    z-index: 10;
  }
  #fv .fixedNav .title {
    font-size: 18px;
    font-weight: 600;
    padding: 6px;
    gap: 12px;
  }
  #fv .fixedNav .title .mailIcon {
    max-width: 21px;
    display: flex;
    align-items: center;
  }
  #fv .fixedNav .title .mailIcon img {
    width: 100%;
  }
  #fv .fixedNav .title:hover, #fv .fixedNav .title:focus {
    transition: all 0.3s;
    opacity: 0.9;
  }
  #fv .fixedNav ul {
    margin-top: 8px;
  }
  #fv .fixedNav ul li {
    padding-left: 20px;
  }
  #fv .fixedNav ul li + li {
    margin-top: 5px;
  }
  #fv .fixedNav ul li a {
    font-size: 15px;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 14px;
  }
  #fv .fixedNav ul li a:hover, #fv .fixedNav ul li a:focus {
    transition: all 0.3s;
    opacity: 0.9;
  }
  #fv .fixedNav ul li a .navTitle {
    font-size: 15px;
    text-align: left;
    width: 100%;
    padding-left: 3px;
  }
  #fv .fixedNav ul li a .telNum {
    font-size: 27px;
    line-height: 1;
    margin-top: 4px;
  }
  #fv .fixedNav ul li a.doctorIcon .telNum {
    color: #2b91c1;
  }
  #fv .fixedNav ul li a.fraskoIcon .telNum {
    color: #3363ab;
  }
  #fv .fixedNav ul li a .phoneCircle,
  #fv .fixedNav ul li a .doctorCircle,
  #fv .fixedNav ul li a .fraskoCircle {
    position: absolute;
    left: -22px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 10px #fff solid;
    box-sizing: content-box;
  }
  #fv .fixedNav ul li a:hover, #fv .fixedNav ul li a:focus {
    opacity: 0.8s;
  }
  #fv .fixedNav .info {
    font-size: 14px;
    margin-top: 10px;
  }
  .slideIn {
    opacity: 0;
    animation: slideInAnime 0.45s cubic-bezier(0.25, 0.1, 0.25, 1) both;
  }
  @keyframes slideInAnime {
    0% {
      opacity: 0;
      transform: translateY(-150px);
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .slideInNext {
    opacity: 0;
    animation: slideInAnime2 0.45s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s both;
  }
  @keyframes slideInAnime2 {
    0% {
      transform: translateY(-150px);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .fadeIn {
    animation-name: fadeInAnime;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-delay: 2s;
  }
  @keyframes fadeInAnime {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadeUp {
    animation: fadeUpAnime 0.4s ease-out forwards;
    animation-delay: 1s;
  }
  @keyframes fadeUpAnime {
    to {
      transform: translateY(0%);
      transition: transform 1s 0s cubic-bezier(0.25, 0, 0, 1);
    }
  }
  #about {
    background: url(../images/home/about-bg.png);
    background-size: cover;
    background-position: center;
    padding-bottom: 110px;
    margin-top: 105px;
  }
  #about::after {
    position: absolute;
    content: "";
    background: url(../images/home/rec-img.png);
    background-position: center center;
    background-repeat: no-repeat;
    right: -40px;
    bottom: -80px;
    width: 146px;
    height: 235px;
    background-size: contain;
  }
  #about .aboutSideImg {
    position: absolute;
    left: 0;
    top: 12.9vw;
    width: 56.25vw;
    max-height: 466px;
    clip-path: polygon(0 0, 100% 0, 87% 100%, 0% 100%);
  }
  #about .inner {
    position: relative;
    z-index: 5;
  }
  #about .inner .aboutCatch {
    margin-left: auto;
    max-width: 464px;
    width: 100%;
    padding-right: 40px;
    margin-top: 30px;
  }
  #about .inner .aboutCatch img {
    aspect-ratio: 930/250;
  }
  #about .inner .aboutText {
    font-size: 18px;
    margin-left: auto;
    width: 42.1%;
    margin-top: 42px;
    line-height: 2.38;
  }
  #about .inner .btnWrap {
    margin-top: 50px;
  }
  #about .inner .btnWrap a .c-arrow::before {
    background: #fff;
  }
  #about .inner .btnWrap a .c-arrow::after {
    width: 6px;
    height: auto;
    left: 50%;
  }
  #about .inner .newsWrap {
    justify-content: space-between;
    margin-top: 170px;
    display: flex;
    gap: min(3.75vw, 60px);
  }
  #about .inner .newsWrap .newsLeft {
    width: calc(100% - 60px - 80%);
    min-width: 160px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap {
    flex-direction: column;
  }
  #about .inner .newsWrap .newsLeft .titleWrap .en {
    font-family: "Lato", sans-serif;
    color: #005b2a;
    font-weight: 700;
    font-style: italic;
    font-size: 30px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap .title {
    font-size: 22px;
    margin-top: 10px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap + a {
    margin-top: 40px;
    font-size: 16px;
    padding: 12px 16px 12px 14px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap + a .c-arrow {
    right: 10px;
  }
  #about .inner .newsWrap .newsLeft .titleWrap + a .c-arrow::after {
    width: 5px;
    height: auto;
    left: 50%;
  }
  #about .inner .newsWrap .newsListArea {
    margin-top: 30px;
    width: 80%;
  }
  #about .inner .newsWrap .newsListArea .newsItem a {
    position: relative;
    border-radius: 10px;
    align-items: center;
    padding: 23px 48px 23px 30px;
    transition: 0.2s ease-in-out;
  }
  #about .inner .newsWrap .newsListArea .newsItem a::before {
    content: "";
    position: absolute;
    background: #bdd7a9;
    height: 1px;
    width: 98%;
    right: 0;
    bottom: 0px;
    transition: 0.2s ease-in-out;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea {
    gap: 38px;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea {
    gap: 24px;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea .cat {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    width: 126px;
    height: 30px;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea .title {
    font-size: 16px;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .c-arrow {
    position: absolute;
    width: 27px;
    height: 27px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-block;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .c-arrow::before {
    content: "";
    width: 27px;
    height: 27px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .c-arrow::after {
    width: 6px;
    height: auto;
    left: calc(50% + 1px);
  }
  #about .inner .newsWrap .newsListArea .newsItem a:hover, #about .inner .newsWrap .newsListArea .newsItem a:focus {
    box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
    opacity: 0.7;
  }
  #about .inner .newsWrap .newsListArea .newsItem a:hover .c-arrow, #about .inner .newsWrap .newsListArea .newsItem a:focus .c-arrow {
    visibility: visible;
  }
  #about .inner .newsWrap .newsListArea .newsItem a:hover::before, #about .inner .newsWrap .newsListArea .newsItem a:focus::before {
    visibility: hidden;
  }
  .process {
    padding-top: 94px;
    padding-bottom: 100px;
    background: url(../images/home/process-bg.png);
    background-size: cover;
    background-position: center;
    margin-top: -344px;
  }
  .process .inner .homeH3 {
    font-size: 28px;
  }
  .process .inner .homeH3 > span {
    font-size: 22px;
  }
  .process .inner .btnWrap {
    margin-top: 40px;
  }
  .process .inner .btnWrap a {
    font-size: 26px;
    max-width: 682px;
    width: 100%;
    padding-block: 30px;
    padding-left: 50px;
    justify-content: space-between;
  }
  .process .inner .btnWrap a .c-arrow {
    height: 38px;
    width: 38px;
  }
  .process .inner .btnWrap a .c-arrow::before {
    height: 38px;
    width: 38px;
  }
  .process .inner .btnWrap a:hover, .process .inner .btnWrap a:focus {
    opacity: 0.8;
  }
  #service {
    padding-top: 160px;
    padding-bottom: 50px;
    margin-inline: auto;
  }
  #service::before {
    content: "";
    position: absolute;
    background: url(../images/home/service-bottom_bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 433px;
    left: 0;
    width: 100%;
    bottom: 340px;
  }
  #service::after {
    content: "";
    position: fixed;
    background: url(../images/home/rec-right-img.png);
    background-repeat: no-repeat;
    background-position: right;
    right: 0;
    top: 574px;
    width: 448px;
    height: 741px;
    background-size: contain;
    z-index: -1;
  }
  #service .inner .hasRec::before {
    content: "";
    background: url(../images/home/service-rec.png);
    position: absolute;
    background-repeat: no-repeat;
    top: 106px;
    left: 0;
    aspect-ratio: 585/711;
    width: 18.1vw;
    height: 100%;
    background-size: contain;
  }
  #service .inner .wrap {
    width: 100%;
    margin: 0 auto;
    padding: 100px 0;
    position: relative;
  }
  #service .inner .wrap .container {
    width: 40.9%;
    margin-top: -100vh;
  }
  #service .inner .wrap .container > div {
    padding-top: 10vw;
    padding-bottom: 15vw;
  }
  #service .inner .wrap .container > div p {
    margin-top: 30px;
    text-align: left;
    line-height: 170%;
  }
  #service .inner .wrap .container > div .serviceTitle {
    font-size: 38px;
    padding-left: 70px;
    padding-top: 0;
  }
  #service .inner .wrap .container > div .serviceTitle.doctor::before {
    width: 37px;
    height: 43px;
  }
  #service .inner .wrap .container > div .serviceText {
    line-height: 1.93;
    margin-top: 30px;
  }
  #service .inner .wrap .container > div + .serviceItem {
    margin-top: 170px;
  }
  #service .inner .wrap .container > div .btnWrap {
    margin-top: 20px;
  }
  #service .inner .wrap .container > div ul li a {
    font-size: 21px;
    padding-block: 20px;
    align-items: center;
    gap: 30px;
    display: flex;
    padding-right: 40px;
    transition: all 0.3s;
  }
  #service .inner .wrap .container > div ul li a:hover, #service .inner .wrap .container > div ul li a:focus {
    opacity: 0.7;
  }
  #service .inner .wrap .container > div ul li a .mini {
    font-size: 20px;
    display: inline;
  }
  #service .inner .wrap .container > div ul li a .c-arrow {
    width: 27px;
    height: 27px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  #service .inner .wrap .container > div ul li a .c-arrow::before {
    content: "";
    width: 27px;
    height: 27px;
    background: #005b2a;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0px;
    bottom: 0;
    margin: auto;
    right: 0;
  }
  #service .inner .wrap .container > div ul li a .c-arrow::after {
    width: 6px;
    height: auto;
    top: calc(50% + 0px);
    left: calc(50% + 1px);
  }
  #service .inner .wrap .container > div ul li a .c-arrow.black::before {
    background: #fff;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.black::after {
    background: url(../images/common/arrow-lightgray.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.orange::before {
    background: #fff;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.lightgray::before {
    background: #4c4c4c;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.skyblue::before {
    background: #2b91c1;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.blue::before {
    background: #3363ab;
  }
  #service .inner .wrap .container > div ul li a:has(.mini) {
    display: block;
  }
  #service .inner .wrap .container > div ul li:not(:first-child) a {
    border-top: 1px solid #202022;
  }
  #service .inner .wrap .container > div.scroll-area1 {
    padding-top: 0;
  }
  #service .inner .wrap .container div:last-child {
    padding-bottom: 25vh;
  }
  #service .inner .wrap .container .scrollBox + .scrollBox {
    margin-top: 180px;
  }
  #service .inner .wrap .images-container {
    width: 46.1vw;
    height: 100vh;
    align-items: center;
    position: sticky;
    position: -webkit-sticky;
    max-width: 740px;
    top: 25vh;
    margin-left: min(46.3vw, 550px);
  }
  #service .inner .wrap .images-container p:first-of-type {
    opacity: 1 !important;
    visibility: visible !important;
  }
  #service .inner .wrap .images-container img {
    max-width: 100%;
    height: auto;
    aspect-ratio: 724/524;
  }
  #service .inner .wrap .images-container p {
    margin: auto;
    display: block;
    visibility: hidden;
    position: absolute;
    transition: 0.8s !important;
    opacity: 0;
    aspect-ratio: 724/524;
    width: 44.3vw;
    max-width: 738px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #service .inner .wrap .images-container p.active {
    visibility: visible;
    opacity: 1;
  }
}
@media print, screen and (min-width: 1025px) and (max-width: 1500px) {
  .message .in .sub .txt {
    left: 0px;
  }
  .message .in .sub img {
    max-width: 630px;
    width: 90%;
    left: 30px;
    top: 80px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1100px) {
  .section + .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  #ghMenuBtn {
    top: 10px;
  }
  .h2TitleWrap {
    color: #005b2a;
    position: relative;
  }
  .h2TitleWrap.hasRec::before {
    position: absolute;
    content: "";
    background: url(../images/home/rec-img.png);
    background-size: contain;
    background-repeat: no-repeat;
    top: 10px;
    left: -50px;
    width: 116px;
    height: 154px;
    aspect-ratio: 229/306;
    z-index: -1;
  }
  #fv .fvNavWrap li a.green {
    background: #005b2a;
  }
  #fv .fvNavWrap li a.skyblue {
    background: #2b91c1;
  }
  #fv .fvNavWrap li a.blue {
    background: #3363ab;
  }
  #fv .fixedNav {
    display: none;
  }
  #fv .fixedNav .title .mailIcon img {
    width: 100%;
  }
  #fv .fixedNav ul li a.doctorIcon .telNum {
    color: #2b91c1;
  }
  #fv .fixedNav ul li a.fraskoIcon .telNum {
    color: #3363ab;
  }
  .slideIn {
    opacity: 0;
    animation: slideInAnime 0.45s cubic-bezier(0.25, 0.1, 0.25, 1) both;
  }
  @keyframes slideInAnime {
    0% {
      opacity: 0;
      transform: translateY(-150px);
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .slideInNext {
    opacity: 0;
    animation: slideInAnime2 0.45s cubic-bezier(0.25, 0.1, 0.25, 1) 0.3s both;
  }
  @keyframes slideInAnime2 {
    0% {
      transform: translateY(-150px);
      opacity: 0;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  .fadeIn {
    animation-name: fadeInAnime;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-delay: 2s;
  }
  @keyframes fadeInAnime {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .fadeUp {
    animation: fadeUpAnime 0.4s ease-out forwards;
    animation-delay: 1s;
  }
  @keyframes fadeUpAnime {
    to {
      transform: translateY(0%);
      transition: transform 1s 0s cubic-bezier(0.25, 0, 0, 1);
    }
  }
  #about .inner {
    position: relative;
    z-index: 5;
  }
  #about .inner .aboutCatch img {
    aspect-ratio: 930/250;
  }
  #about .inner .btnWrap a .c-arrow::before {
    background: #fff;
  }
  #about .inner .newsWrap .newsLeft .titleWrap .en {
    font-family: "Lato", sans-serif;
    color: #005b2a;
    font-weight: 700;
    font-style: italic;
  }
  #about .inner .newsWrap .newsListArea .newsItem a .textArea .infoArea .cat {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #about .inner .newsWrap .newsListArea .newsItem a:hover, #about .inner .newsWrap .newsListArea .newsItem a:focus {
    box-shadow: 0px 10px 10px -5px rgba(0, 91, 42, 0.2);
    opacity: 0.7;
  }
  #about .inner .newsWrap .newsListArea .newsItem a:hover .c-arrow, #about .inner .newsWrap .newsListArea .newsItem a:focus .c-arrow {
    visibility: visible;
  }
  #service::before {
    content: "";
    position: absolute;
    background: url(../images/home/service-bottom_bg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 433px;
    left: 0;
    width: 100%;
    bottom: 340px;
  }
  #service .inner .wrap .container > div p {
    text-align: left;
    line-height: 170%;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.black::before {
    background: #fff;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.black::after {
    background: url(../images/common/arrow-lightgray.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.orange::before {
    background: #fff;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.orange::after {
    background: url(../images/common/arrow-orange.svg);
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 83/150;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.lightgray::before {
    background: #4c4c4c;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.skyblue::before {
    background: #2b91c1;
  }
  #service .inner .wrap .container > div ul li a .c-arrow.blue::before {
    background: #3363ab;
  }
  #service .inner .wrap .container > div ul li:not(:first-child) a {
    border-top: 1px solid #202022;
  }
  #service .inner .wrap .images-container p.active {
    visibility: visible;
    opacity: 1;
  }
}