@charset "UTF-8";

// IMPPRT INIT

@import "_setting";
$type: "";
@import "_home";

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (max-width:$breakpoint_sp) {
	$type: sp;
	@import "_home";
}


// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media print, screen and (min-width:$breakpoint_pc) {
	$type: pc;
	@import "_home";
}

@media print, screen and (min-width:1025px) and (max-width:1500px) {
	.message .in .sub .txt{
		left: 0px;
	}
	.message .in .sub img{
		max-width: 630px;
		width: 90%;
		left: 30px;
		top: 80px;
	}
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
  $type: tb;
  @import "_home";
}

// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
